<template>
  <v-select
    v-model="value"
    :items="options"
    :label="label"
    dense
    outlined
    clearable
    hide-details
    multiple
  >
    <template v-slot:selection="{ item, index }">
      <v-chip x-small v-if="index === 0"
        ><span>{{ item.text }}</span></v-chip
      >
      <span v-if="index === 1" class="grey--text text-caption"
        >(+{{ value.length - 1 }})</span
      >
    </template>
  </v-select>
</template>

<script>
import { debounce } from "@/libs/helpers";

export default {
  name: "SelectMultipleFilter",
  props: {
    name: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    value: null,
  }),
  watch: {
    value() {
      this.onFilter();
    },
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit("onFilter", {
        name: this.name,
        value: this.value,
      });
    }, 500),
  },
};
</script>
